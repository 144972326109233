
import {Link} from "react-router-dom";

export default function Sidebar(){
    return(
        <>
        
        <div class="flex w-64 h-screen flex-col justify-between border-e bg-white">
            <div class="px-4 py-6">
                
                <h2 className="font-bold text-xl">CreativeOcto.io</h2>

                <ul class="mt-6 space-y-1">
                <li>
                    <Link to="/manage/dashboard" class="block rounded-lg bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700" >
                    Dashboard
                    </Link>
                </li>

                <li>
                    <details class="group [&_summary::-webkit-details-marker]:hidden">
                    <summary class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                        <span class="text-sm font-medium"> Clients </span>
                        <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                            />
                        </svg>
                        </span>
                    </summary>

                    <ul class="mt-2 space-y-1 px-4">
                        <li>
                            <Link to="/manage/client/company" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                Companies
                            </Link>
                        </li>
                        <li>
                            <Link to="/manage/client/brands" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                Brands
                            </Link>
                        </li>
                        <li>
                            <Link to="/manage/client/users" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                Users
                            </Link>
                        </li>
                    </ul>
                    </details>
                </li>

                <li>
                    <Link to="/manage/projects" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                        Projects
                    </Link>
                </li>

                <li>
                    <a href="/manage/billing/all" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                        Billing
                    </a>
                </li>

                <li>
                    <Link to="/manage/users" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                        Users
                    </Link>
                </li>

                <li>
                    <Link to="/manage/packages" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                        Packages
                    </Link>
                </li>

                
                <li>
                    <details class="group [&_summary::-webkit-details-marker]:hidden">
                    <summary class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                        <span class="text-sm font-medium"> Website CMS </span>
                        <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                            />
                        </svg>
                        </span>
                    </summary>

                    <ul class="mt-2 space-y-1 px-4">
                        <li>
                            <a href="/manage/leads/contactform" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                Contact Us Leads
                            </a>
                        </li>
                        <li>
                            <a href="/manage/leads/demo" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                Demo Leads
                            </a>
                        </li>
                        <li>
                            <a href="/manage/portfolio" class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                Manage Portfolio
                            </a>
                        </li>
                    </ul>
                    </details>
                </li>

                
                
                </ul>
            </div>

            
        </div>
        
        
        
        </>
    )
}