
import React, { useState, useEffect } from "react";
import axiosInstance from "../../config/axiosInstance";
import PageTitle from "../../components/PageTitle";
import PageLoadingSpinner from "../../components/PageLoading";
import {Link} from "react-router-dom";

import formatToLocaleDate from "../../helpers/dateTimeConverter"



export default function DemoLeads() {
    const [loading, setLoading] = useState(true);
    const [leads, setLeads] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);

    const getLeads = async () => {
        try{
            const response = await axiosInstance.get(`/admin/leads/demoleads`);
            setLeads(response.data.leads);
        } catch(error){
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const openModal = (lead) => {
        setSelectedLead(lead);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedLead(null);
    };

    useEffect(() =>{
        getLeads();
    },[])

    if(loading){
        return (
            <PageLoadingSpinner />
        )
    }

    return (
        <>

            <PageTitle title={"Manage Portfolio"}/>

            <div className="flex justify-between mb-5">
                <h2 className="font-bold text-xl">Demo Leads</h2>
            </div>

            <div className="rounded-lg border border-gray-200">
                <div className="overflow-x-auto rounded-t-lg">
                    <table className="min-w-full divide-y-2 divide-gray-200 bg-white">
                        <thead className="text-left">
                        <tr className="uppercase text-xs" >
                            <th className="whitespace-nowrap px-4 py-2 text-gray-700">Full Name</th>
                            <th className="whitespace-nowrap px-4 py-2 text-gray-700">Website</th>
                            <th className="whitespace-nowrap px-4 py-2 text-gray-700">Email</th>
                            <th className="whitespace-nowrap px-4 py-2 text-gray-700">Contact</th>
                            <th className="whitespace-nowrap px-4 py-2 text-gray-700">Created At</th>
                        </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200">
                        {leads && leads.length > 0 ? (
                            leads.map(lead => (
                                <tr className="capitalize" key={lead.id} onClick={() => openModal(lead)}>
                                    <td className="whitespace-nowrap px-4 py-2">{lead.fullname}</td>
                                    <td className="whitespace-nowrap px-4 py-2">{lead.website}</td>
                                    <td className="whitespace-nowrap px-4 py-2">{lead.email}  </td>
                                    <td className="whitespace-nowrap px-4 py-2">{lead.contact} </td>

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="px-4 py-2 text-center">No companies found.</td>
                            </tr>
                        )}

                        </tbody>
                    </table>
                </div>
            </div>


            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg p-6 w-1/2">
                        <h3 className="text-lg font-bold mb-4">Lead Details</h3>
                        {selectedLead && (
                            <div className={`space-y-3`}>
                                <p><strong>Full Name:</strong> {selectedLead.fullname}</p>
                                <p><strong>Website:</strong> {selectedLead.website}</p>
                                <p><strong>Email:</strong> {selectedLead.email}</p>
                                <p><strong>Contact:</strong> {selectedLead.contact}</p>
                                <p><strong>Industry:</strong> {selectedLead.industry}</p>
                                <p><strong>Inquiry:</strong> {selectedLead.inquiry}</p>
                                <p><strong>Demo Date time:</strong> {selectedLead.demodate}</p>
                                <p><strong>Created At:</strong> {formatToLocaleDate(selectedLead.createdAt)}</p>
                            </div>
                        )}
                        <div className="mt-4 text-right">
                            <button
                                className="px-4 py-2 bg-red-500 text-white rounded"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}