

export default function formatToLocaleDate(dateString) {
    // Retrieve the timezone (locale) from localStorage or use a default
    const timezone = localStorage.getItem("locale") || "America/Toronto";

    // Create a new Date object from the input ISO date string
    const date = new Date(dateString);

    // Use Intl.DateTimeFormat to format the date with timezone
    return new Intl.DateTimeFormat("en-US", {
        timeZone: timezone,
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true // Optional: Adjust to display 12-hour or 24-hour time
    }).format(date);
}




