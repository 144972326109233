import React, { lazy, Suspense } from 'react';

import { Route, Routes } from "react-router-dom";

import ErrorBoundary from '../components/ErrorHandling/ErrorBoundary';

import PageLoadingSpinner from '../components/PageLoading';
import DefaultLayout from '../pages/layout/DefaultLayout';
import PrivateRoute from '../components/PrivateRoute';
import TestInvoicePage from '../pages/TestInvoicePage';
import AllBrands from '../pages/brands/AllBrands';
import AllProjects from '../pages/projects/AllProjects';
import DemoLeads from "../pages/leads/DemoLeads";

const Dashboard = lazy(() => import('../pages/Dashboard'));

// Client Pages
const AllCompanies = lazy(() => import('../pages/client/companies/AllCompanies'));
const SingleCompany = lazy(() => import('../pages/client/companies/SingleCompany'));
const CreateCompany = lazy(() => import('../pages/client/companies/CreateCompany'));

const SingleClientUser = lazy(() => import('../pages/client/users/SingleClientUser'));
const AllUsers = lazy(() => import('../pages/client/users/AllUsers'));

const SingleBrand = lazy(() => import('../pages/brands/SingleBrand'));
// const UploadBrandAsset = lazy(() => import('../pages/brands/UploadBrandAssetss'));

const CreateProject = lazy(() => import('../pages/projects/CreateProject'));
const SingleProject = lazy(() => import('../pages/projects/SingleProject'))

const ChatTestPage = lazy(() => import('../pages/chatTestPage'));

const AllAdminUsers = lazy(() => import('../pages/users/AllUsers'));
const CreateUser = lazy(() => import('../pages/users/CreateUser'));

const AllPackages = lazy(() => import('../pages/packages/AllPackages'));
const EditPackage = lazy(() => import('../pages/packages/EditPackage'));
const CreatePackage = lazy(() => import('../pages/packages/CreatePackage'));

const UserSettings = lazy(() => import('../pages/users/UserSettings'));
const Notifications = lazy(() => import('../pages/users/Notifications'));

const FileDownload = lazy(() => import('../pages/FileDownload'));

// Billing Imports
const AllInvoices = lazy(() => import('../pages/billing/AllInvoices'));
const SingleInvoice = lazy(() => import('../pages/billing/SingleInvoice'));
const CreateInvoice = lazy(() => import('../pages/billing/CreateInvoice'));

//Portfolio
const ManagePortfolio = lazy(() => import('../pages/portfolio/ManagePortfolio'));

//Leads
const ContactFormLeads = lazy(() => import('../pages/leads/ContactFormLeads'));


export default function MainRoutes(){
    return(
        <Suspense fallback={<div className='w-full h-screen flex justify-center items-center'><PageLoadingSpinner /></div>}>
            <Routes>
            <Route path="/download/:filepath" element={<FileDownload />}/>

                <Route path="/manage/*" element={<DefaultLayout />}>

                    <Route path="dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

                        <Route path="testinvoice" element={<TestInvoicePage />} />


                    {/* CLIENT ROUTES */}
                        <Route path="client/company" element={<PrivateRoute><AllCompanies /></PrivateRoute>} />
                        <Route path="company/create" element={<PrivateRoute><CreateCompany /></PrivateRoute>} />

                        {/* Single Company Routes */}
                        <Route path="company/:company_id" element={<PrivateRoute><SingleCompany /></PrivateRoute>} />
                        <Route path="company/:company_id/projects" element={<PrivateRoute><SingleCompany /></PrivateRoute>} />
                        {/* End of Single company routes */}

                        {/* Client User Routes */}
                        <Route path="client/users/" element={<PrivateRoute><AllUsers /></PrivateRoute>} />
                        <Route path="client/user/:user_id" element={<PrivateRoute><SingleClientUser /></PrivateRoute>} />
                        {/* End of Client user Routes */}

                        {/* Brand Routes */}
                        <Route path="client/brands" element={<PrivateRoute><AllBrands /></PrivateRoute>} />
                        <Route path="brand/:brand_id" element={<PrivateRoute><SingleBrand /></PrivateRoute>} />
                        {/* <Route path="brand/:brand_id/assets/upload" element={<PrivateRoute><UploadBrandAsset /></PrivateRoute>} /> */}
                        {/* End Brand Routes */}

                    {/* END OF CLIENT ROUTES */}

                    {/* Project Routes */}
                        <Route path="projects/" element={<PrivateRoute><AllProjects /></PrivateRoute>} />
                        <Route path="project/:project_id" element={<PrivateRoute><SingleProject /></PrivateRoute>} />
                        <Route path="project/create" element={<PrivateRoute><CreateProject /></PrivateRoute>} />

                        <Route path="chat" element={<PrivateRoute><ChatTestPage /></PrivateRoute>} />
                    {/* End of Project Routes */}

                    {/* Billing Routes */}
                    <Route path="billing/all" element={<PrivateRoute><AllInvoices /></PrivateRoute>} />
                    <Route path="billing/:invoice_number" element={<PrivateRoute><SingleInvoice /></PrivateRoute>} />
                    <Route path="billing/create" element={<PrivateRoute><CreateInvoice /></PrivateRoute>} />
                    {/* End of Billing Routes */}

                    {/* User Routes */}
                        <Route path="users/" element={<PrivateRoute><AllAdminUsers /></PrivateRoute>} />
                        <Route path="user/create" element={<PrivateRoute><CreateUser /></PrivateRoute>} />
                    {/* End of User Routes */}

                    {/* Package Routes */}
                        <Route path="packages/" element={<PrivateRoute><AllPackages /></PrivateRoute>} />
                        <Route path="package/create" element={<PrivateRoute><CreatePackage /></PrivateRoute>} />
                        <Route path="package/:package_id" element={<PrivateRoute><EditPackage /></PrivateRoute>} />
                    {/* End of Package Routes */}

                    {/* User Routes */}
                        <Route path="profile/settings/" element={<PrivateRoute><UserSettings /></PrivateRoute>} />
                        <Route path="notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
                    {/* End of User Routes */}

                    {/*Portfolio Routes*/}
                        <Route path="portfolio" element={<PrivateRoute><ManagePortfolio /></PrivateRoute>} />
                    {/*End of portfolio routes*/}

                    {/*Leads Routes*/}
                    <Route path="leads/contactform" element={<PrivateRoute><ContactFormLeads /></PrivateRoute>} />
                    <Route path="leads/demo" element={<PrivateRoute><DemoLeads /></PrivateRoute>} />
                    {/*End of Leads routes*/}


                </Route>
                
            </Routes>
        </Suspense>
    )
}
